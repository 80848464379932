import React from 'react'
import { Link } from 'react-router-dom'

const TaxiService = () => {
    return (
        <>
            <div className="container-fluid service py-5">
                <div className="container py-5">
                    <div className="text-center mx-auto pb-5 wow fadeInUp" data-wow-delay="0.2s" style={{ maxWidth: '800px' }}>
                        <h4 className="text-primary">Our Services</h4>
                        <h1 className="display-4 mb-4">Our Cars For You</h1>
                        <p className="mb-0 text-dark">Experience the best of our curated tours with a seamless booking process. Choose from a variety of tour packages tailored to suit your preferences and enjoy a memorable journey with us.
                        </p>
                    </div>
                    <div className="row g-4 justify-content-center">
                        <div className="col-md-6 col-lg-6 col-xl-3 wow fadeInUp" data-wow-delay="0.2s">
                            <div className="service-item">
                                <div className="service-img">
                                    <img src="/img/Car/Sedan.jpg" className="img-fluid rounded-top w-100" alt="" style={{ height: '200px' }} />
                                    <div className="service-icon p-3">
                                        <i className="fa fa-car fa-2x"></i>
                                    </div>
                                </div>
                                <div className="service-content p-4">
                                    <div className="service-content-inner">
                                        <a className="d-inline-block h4 mb-4"><b>Nainital drop Sadan car 1800</b>
                                        </a>
                                        <Link to='/car-book-page' className="btn btn-primary rounded-pill py-2 px-4">Book Now</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-6 col-xl-3 wow fadeInUp" data-wow-delay="0.2s">
                            <div className="service-item">
                                <div className="service-img">
                                    <img src="/img/Car/SUV.jpg" className="img-fluid rounded-top w-100" alt="" style={{ height: '200px' }} />
                                    <div className="service-icon p-3">
                                        <i className="fa fa-car fa-2x"></i>
                                    </div>
                                </div>
                                <div className="service-content p-4">
                                    <div className="service-content-inner">
                                        <a className="d-inline-block h4 mb-4"><b>Nainital drop SUV 2500</b>
                                        </a>
                                        <Link to='/car-book-page' className="btn btn-primary rounded-pill py-2 px-4">Book Now</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-6 col-xl-3 wow fadeInUp" data-wow-delay="0.2s">
                            <div className="service-item">
                                <div className="service-img">
                                    <img src="/img/Car/tempo-traveller.png" className="img-fluid rounded-top w-100" alt="" style={{ height: '200px' }} />
                                    <div className="service-icon p-3">
                                        <i className="fa fa-car fa-2x"></i>
                                    </div>
                                </div>
                                <div className="service-content p-4">
                                    <div className="service-content-inner">
                                        <a className="d-inline-block h4 mb-4"><b>Nainital drop Tempo traveler 5000</b>
                                        </a>
                                        <Link to='/car-book-page' className="btn btn-primary rounded-pill py-2 px-4">Book Now</Link>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 col-lg-6 col-xl-3 wow fadeInUp" data-wow-delay="0.4s">
                            <div className="service-item">
                                <div className="service-img">
                                    <img src="/img/Car/Sedan.jpg" className="img-fluid rounded-top w-100" alt="" style={{ height: '200px' }} />
                                    <div className="service-icon p-3">
                                        <i className="fa fa-car fa-2x"></i>
                                    </div>
                                </div>
                                <div className="service-content p-4">
                                    <div className="service-content-inner">
                                        <a className="d-inline-block h4 mb-4"><b>Bhimtal drop Sadan car drop 1500</b></a>
                                        <Link to='/car-book-page' className="btn btn-primary rounded-pill py-2 px-4">Book Now</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-6 col-xl-3 wow fadeInUp" data-wow-delay="0.4s">
                            <div className="service-item">
                                <div className="service-img">
                                    <img src="/img/Car/SUV.jpg" className="img-fluid rounded-top w-100" alt="" style={{ height: '200px' }} />
                                    <div className="service-icon p-3">
                                        <i className="fa fa-car fa-2x"></i>
                                    </div>
                                </div>
                                <div className="service-content p-4">
                                    <div className="service-content-inner">
                                        <a className="d-inline-block h4 mb-4"><b>Bhimtal drop SUV drop 2000</b></a>
                                        <Link to='/car-book-page' className="btn btn-primary rounded-pill py-2 px-4">Book Now</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-6 col-xl-3 wow fadeInUp" data-wow-delay="0.4s">
                            <div className="service-item">
                                <div className="service-img">
                                    <img src="/img/Car/tempo-traveller.png" className="img-fluid rounded-top w-100" alt="" style={{ height: '200px' }} />
                                    <div className="service-icon p-3">
                                        <i className="fa fa-car fa-2x"></i>
                                    </div>
                                </div>
                                <div className="service-content p-4">
                                    <div className="service-content-inner">
                                        <a className="d-inline-block h4 mb-4"><b>Bhimtal drop Tempu traveler 4000</b></a>
                                        <Link to='/car-book-page' className="btn btn-primary rounded-pill py-2 px-4">Book Now</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-6 col-xl-3 wow fadeInUp" data-wow-delay="0.6s">
                            <div className="service-item">
                                <div className="service-img">
                                    <img src="/img/Car/Sedan.jpg" className="img-fluid rounded-top w-100" alt="" style={{ height: '200px' }} />
                                    <div className="service-icon p-3">
                                        <i className="fa fa-car fa-2x"></i>
                                    </div>
                                </div>
                                <div className="service-content p-4">
                                    <div className="service-content-inner">
                                        <a className="d-inline-block h4 mb-4"><b>Almora drop Sadan car 3000</b></a>
                                        <Link to='/car-book-page' className="btn btn-primary rounded-pill py-2 px-4">Book Now</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-6 col-xl-3 wow fadeInUp" data-wow-delay="0.6s">
                            <div className="service-item">
                                <div className="service-img">
                                    <img src="/img/Car/SUV.jpg" className="img-fluid rounded-top w-100" alt="" style={{ height: '200px' }} />
                                    <div className="service-icon p-3">
                                        <i className="fa fa-car fa-2x"></i>
                                    </div>
                                </div>
                                <div className="service-content p-4">
                                    <div className="service-content-inner">
                                        <a className="d-inline-block h4 mb-4"><b>Almora drop SUV 4500</b></a>
                                        <Link to='/car-book-page' className="btn btn-primary rounded-pill py-2 px-4">Book Now</Link>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="col-md-6 col-lg-6 col-xl-3 wow fadeInUp" data-wow-delay="0.6s">
                            <div className="service-item">
                                <div className="service-img">
                                    <img src="/img/Car/tempo-traveller.png" className="img-fluid rounded-top w-100" alt="" style={{ height: '200px' }} />
                                    <div className="service-icon p-3">
                                        <i className="fa fa-car fa-2x"></i>
                                    </div>
                                </div>
                                <div className="service-content p-4">
                                    <div className="service-content-inner">
                                        <a className="d-inline-block h4 mb-4"><b>Almora drop Tempu traveler 7000</b></a>
                                        <Link to='/car-book-page' className="btn btn-primary rounded-pill py-2 px-4">Book Now</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-6 col-xl-3 wow fadeInUp" data-wow-delay="0.8s">
                            <div className="service-item">
                                <div className="service-img">
                                    <img src="/img/Car/Sedan.jpg" className="img-fluid rounded-top w-100" alt="" style={{ height: '200px' }} />
                                    <div className="service-icon p-3">
                                        <i className="fa fa-car fa-2x"></i>
                                    </div>
                                </div>
                                <div className="service-content p-4">
                                    <div className="service-content-inner">
                                        <a className="d-inline-block h4 mb-4"><b>Ranikt drop Sadan car 3000</b>
                                        </a>
                                        <Link to='/car-book-page' className="btn btn-primary rounded-pill py-2 px-4">Book now</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-6 col-xl-3 wow fadeInUp" data-wow-delay="0.8s">
                            <div className="service-item">
                                <div className="service-img">
                                    <img src="/img/Car/SUV.jpg" className="img-fluid rounded-top w-100" alt="" style={{ height: '200px' }} />
                                    <div className="service-icon p-3">
                                        <i className="fa fa-car fa-2x"></i>
                                    </div>
                                </div>
                                <div className="service-content p-4">
                                    <div className="service-content-inner">
                                        <a className="d-inline-block h4 mb-4"><b>Ranikt drop SUV 4500</b>
                                        </a>
                                        <Link to='/car-book-page' className="btn btn-primary rounded-pill py-2 px-4">Book now</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-6 col-xl-3 wow fadeInUp" data-wow-delay="0.8s">
                            <div className="service-item">
                                <div className="service-img">
                                    <img src="/img/Car/tempo-traveller.png" className="img-fluid rounded-top w-100" alt="" style={{ height: '200px' }} />
                                    <div className="service-icon p-3">
                                        <i className="fa fa-car fa-2x"></i>
                                    </div>
                                </div>
                                <div className="service-content p-4">
                                    <div className="service-content-inner">
                                        <a className="d-inline-block h4 mb-4"><b>Ranikt drop Tempu traveler 7000</b>
                                        </a>
                                        <Link to='/car-book-page' className="btn btn-primary rounded-pill py-2 px-4">Book now</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row g-4 justify-content-center mt-3">
                        <div className="col-md-6 col-lg-6 col-xl-3 wow fadeInUp" data-wow-delay="0.2s">
                            <div className="service-item">
                                <div className="service-img">
                                    <img src="/img/Car/Sedan.jpg" className="img-fluid rounded-top w-100" alt="" style={{ height: '200px' }} />
                                    <div className="service-icon p-3">
                                        <i className="fa fa-car fa-2x"></i>
                                    </div>
                                </div>
                                <div className="service-content p-4">
                                    <div className="service-content-inner">
                                        <a className="d-inline-block h4 mb-4"><b>Kainchi dham drop Sadan car 2000</b></a>
                                        <Link to='/car-book-page' className="btn btn-primary rounded-pill py-2 px-4">Book Now</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-6 col-xl-3 wow fadeInUp" data-wow-delay="0.2s">
                            <div className="service-item">
                                <div className="service-img">
                                    <img src="/img/Car/SUV.jpg" className="img-fluid rounded-top w-100" alt="" style={{ height: '200px' }} />
                                    <div className="service-icon p-3">
                                        <i className="fa fa-car fa-2x"></i>
                                    </div>
                                </div>
                                <div className="service-content p-4">
                                    <div className="service-content-inner">
                                        <a className="d-inline-block h4 mb-4"><b>Kainchi dham drop SUV 3500</b></a>
                                        <Link to='/car-book-page' className="btn btn-primary rounded-pill py-2 px-4">Book Now</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-6 col-xl-3 wow fadeInUp" data-wow-delay="0.2s">
                            <div className="service-item">
                                <div className="service-img">
                                    <img src="/img/Car/tempo-traveller.png" className="img-fluid rounded-top w-100" alt="" style={{ height: '200px' }} />
                                    <div className="service-icon p-3">
                                        <i className="fa fa-car fa-2x"></i>
                                    </div>
                                </div>
                                <div className="service-content p-4">
                                    <div className="service-content-inner">
                                        <a className="d-inline-block h4 mb-4"><b>Kainchi dham drop Tempu traveler 5500</b></a>
                                        <Link to='/car-book-page' className="btn btn-primary rounded-pill py-2 px-4">Book Now</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-6 col-xl-3 wow fadeInUp" data-wow-delay="0.4s">
                            <div className="service-item">
                                <div className="service-img">
                                    <img src="/img/Car/Sedan.jpg" className="img-fluid rounded-top w-100" alt="" style={{ height: '200px' }} />
                                    <div className="service-icon p-3">
                                        <i className="fa fa-car fa-2x"></i>
                                    </div>
                                </div>
                                <div className="service-content p-4">
                                    <div className="service-content-inner">
                                        <a className="d-inline-block h4 mb-4"><b>Mukteshwar drop Sadan 3500</b></a>
                                        <Link to='/car-book-page' className="btn btn-primary rounded-pill py-2 px-4">Book Now</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-6 col-xl-3 wow fadeInUp" data-wow-delay="0.4s">
                            <div className="service-item">
                                <div className="service-img">
                                    <img src="/img/Car/SUV.jpg" className="img-fluid rounded-top w-100" alt="" style={{ height: '200px' }} />
                                    <div className="service-icon p-3">
                                        <i className="fa fa-car fa-2x"></i>
                                    </div>
                                </div>
                                <div className="service-content p-4">
                                    <div className="service-content-inner">
                                        <a className="d-inline-block h4 mb-4"><b>Mukteshwar drop SUV 4500</b></a>
                                        <Link to='/car-book-page' className="btn btn-primary rounded-pill py-2 px-4">Book Now</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-6 col-xl-3 wow fadeInUp" data-wow-delay="0.4s">
                            <div className="service-item">
                                <div className="service-img">
                                    <img src="/img/Car/car-img-5.jpg" className="img-fluid rounded-top w-100" alt="" style={{ height: '200px' }} />
                                    <div className="service-icon p-3">
                                        <i className="fa fa-car fa-2x"></i>
                                    </div>
                                </div>
                                <div className="service-content p-4">
                                    <div className="service-content-inner">
                                        <a className="d-inline-block h4 mb-4"><b>One Way Taxi Service</b></a>
                                        <div className="row mt-4">
                                            <div className="col">
                                                <Link to='/car-book-page' className="btn btn-primary rounded-pill py-2 px-4">Book Now</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-6 col-xl-3 wow fadeInUp" data-wow-delay="0.4s">
                            <div className="service-item">
                                <div className="service-img">
                                    <img src="/img/Car/car-img-6.jpg" className="img-fluid rounded-top w-100" alt="" style={{ height: '200px' }} />
                                    <div className="service-icon p-3">
                                        <i className="fa fa-car fa-2x"></i>
                                    </div>
                                </div>
                                <div className="service-content p-4">
                                    <div className="service-content-inner">
                                        <a className="d-inline-block h4 mb-4"><b>Nainital to Delhi drop</b></a>
                                        <div className="row mt-4">
                                            <div className="col">
                                                <Link to='/car-book-page' className="btn btn-primary rounded-pill py-2 px-4">Book Now</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-6 col-xl-3 wow fadeInUp" data-wow-delay="0.4s">
                            <div className="service-item">
                                <div className="service-img">
                                    <img src="/img/Car/car-img7.jpg" className="img-fluid rounded-top w-100" alt="" style={{ height: '200px' }} />
                                    <div className="service-icon p-3">
                                        <i className="fa fa-car fa-2x"></i>
                                    </div>
                                </div>
                                <div className="service-content p-4">
                                    <div className="service-content-inner">
                                        <a className="d-inline-block h4 mb-4"><b>Haldwani to Delhi drop</b></a>
                                        <Link to='/car-book-page' className="btn btn-primary rounded-pill py-2 px-4">Book Now</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-6 col-xl-3 wow fadeInUp" data-wow-delay="0.4s">
                            <div className="service-item">
                                <div className="service-img">
                                    <img src="/img/Car/car-img-8.jpg" className="img-fluid rounded-top w-100" alt="" style={{ height: '200px' }} />
                                    <div className="service-icon p-3">
                                        <i className="fa fa-car fa-2x"></i>
                                    </div>
                                </div>
                                <div className="service-content p-4">
                                    <div className="service-content-inner">
                                        <a className="d-inline-block h5 mb-4"><b>Haldwani to Dehradun drop</b></a>
                                        <Link to='/car-book-page' className="btn btn-primary rounded-pill py-2 px-4">Book Now</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default TaxiService
import React, { useState } from 'react';

const ContactUs = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        subject: '',
        message: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        // Construct the mailto link
        const { name, email, phone, subject, message } = formData;
        const mailtoLink = `mailto:vinodsbisht2492@gmail.com?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(
            `Name: ${name}\nEmail: ${email}\nPhone: ${phone}\n\nMessage: ${message}`
        )}`;

        // Open the default email client
        window.location.href = mailtoLink;
    };

    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col p-5">
                        <div className="row">
                            <div className="col text-center p-5  pb-1" style={{ backgroundColor: '#ecf4f9', height: '200px', width: '100%', padding: '10px', margin: '10px', borderRadius: '15px' }}>
                                <h2 className='text-primary'><b>Office Location</b></h2>
                                <p style={{paddingBottom:'5px'}}  className='text-dark'>Haldwani, NH-109, near Bus Stand Road, Banbhoolpura, Haldwani, Uttarakhand, 263139</p>
                            </div>

                            <div className="col text-center pt-5" style={{ backgroundColor: '#ecf4f9', height: '200px', width: '100%', padding: '10px', margin: '10px', borderRadius: '15px' }}>
                                <h2 className='text-primary'><b>Email Address</b></h2>
                                <p className='text-dark'>vinodsbisht2492@gmail.com</p>
                            </div>

                            <div className="col text-center p-5" style={{ backgroundColor: '#ecf4f9', height: '200px', width: '100%', padding: '10px', margin: '10px', borderRadius: '15px' }}>
                                <h2 className='text-primary'><b>Contact Us</b></h2>
                                <p  className='text-dark'>7617712274</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <div className="container py-5">
                            <div className="text-center mx-auto pb-5" style={{ maxWidth: '800px' }}>
                                <h4 className="text-primary">Contact Us</h4>
                                <h1 className="display-4 mb-4">If you have any comments please apply now</h1>
                            </div>
                            <div className="row bg-light g-5">
                                <div className="col-xl-6">
                                    <div className="contact-img d-flex justify-content-center">
                                        <div className="contact-img-inner">
                                            <img src="/img/contact-us_.png" className="img-fluid" style={{ width: '100%' }} alt="Image" />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-6">
                                    <div>
                                        <h4 className="text-primary">Send Your Message</h4>
                                        <form onSubmit={handleSubmit}>
                                            <div className="row g-3">
                                                <div className="col-lg-12 col-xl-6">
                                                    <div className="form-floating">
                                                        <input type="text" className="form-control border-0" id="name" name="name" placeholder="Your Name" onChange={handleChange} required/>
                                                        <label htmlFor="name">Your Name</label>
                                                    </div>
                                                </div>
                                                <div className="col-lg-12 col-xl-6">
                                                    <div className="form-floating">
                                                        <input type="email" className="form-control border-0" id="email" name="email" placeholder="Your Email" onChange={handleChange} required/>
                                                        <label htmlFor="email">Your Email</label>
                                                    </div>
                                                </div>
                                                <div className="col-lg-12 col-xl-12">
                                                    <div className="form-floating">
                                                        <input type="text" className="form-control border-0" id="phone" name="phone" placeholder="Phone" onChange={handleChange} required/>
                                                        <label htmlFor="phone">Your Phone</label>
                                                    </div>
                                                </div>
                                                <div className="col-lg-12">
                                                    <div className="form-floating">
                                                        <input type="text" className="form-control border-0" id="subject" name="subject" placeholder="Subject" onChange={handleChange} required/>
                                                        <label htmlFor="subject">Subject</label>
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <div className="form-floating">
                                                        <textarea className="form-control border-0" placeholder="Leave a message here" id="message" name="message" style={{ height: '120px' }} onChange={handleChange} required></textarea>
                                                        <label htmlFor="message">Message</label>
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <button className="btn btn-primary w-100 py-3" type="submit">Send Message</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="rounded">
                                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3482.3335495748647!2d79.52781147476024!3d29.213741957451788!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39a09bbbd6ba56a3%3A0x285992b40cfc9015!2sHILLS%20HEART%20TOUR%20%26%20TRAVELS!5e0!3m2!1sen!2sin!4v1721804222742!5m2!1sen!2sin" width="100%" height="450" style={{border:'0'}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ContactUs;

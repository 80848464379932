import React from 'react'

const AlmoraTour = () => {
    return (
        <>
            <div className="text-center mx-auto pb-5 wow fadeInUp" data-wow-delay="0.2s" style={{ maxWidth: '800px' }}>
                <h4 className="text-primary">Tour Package</h4>
                <h1 className="display-4 mb-4">Almora Tour Packages</h1>
                <p className="mb-0">Almora is a charming hill station nestled in the Kumaon region of Uttarakhand. Known for its rich cultural heritage, stunning natural beauty, and pleasant weather, Almora is a popular destination for nature lovers and those seeking a tranquil retreat.</p>
            </div>
            <div className="container-fluid faq-section pb-5">
                <div className="container pb-5">
                    <div className="row mt-5 g-5 align-items-center">
                        <div className="col-xl-6 wow fadeInRight" data-wow-delay="0.4s">
                            <div>
                                <h4 className="text-primary">Tour Details</h4>
                                <div className="row g-3">
                                    <div className="col text-primary">
                                        <div className="accordion accordion-flush bg-light" id="accordionFlushExample1">
                                            <div className="accordion-item">
                                                <h2 className="accordion-header">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne1" aria-expanded="false" aria-controls="flush-collapseOne1">
                                                        Day 1
                                                    </button>
                                                </h2>
                                                <div id="flush-collapseOne1" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample1">
                                                    <div className="accordion-body">
                                                        Almora local site seen Nanda Devi temple. Kasar devi temple.mall road.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion accordion-flush mt-2 bg-light" id="accordionFlushExample2">
                                            <div className="accordion-item">
                                                <h2 className="accordion-header">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne2" aria-expanded="false" aria-controls="flush-collapseOne2">
                                                        Day 2
                                                    </button>
                                                </h2>
                                                <div id="flush-collapseOne2" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample2">
                                                    <div className="accordion-body">
                                                        Almora other side site seen  sury katarmal temple Ramkrishna ashram.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion accordion-flush bg-light mt-2" id="accordionFlushExample3">
                                            <div className="accordion-item">
                                                <h2 className="accordion-header">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne3" aria-expanded="false" aria-controls="flush-collapseOne3">
                                                        Day 3
                                                    </button>
                                                </h2>
                                                <div id="flush-collapseOne3" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample3">
                                                    <div className="accordion-body">
                                                        Almora to dorp haldwani
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <button className="btn btn-primary rounded-pill py-3 px-5 mt-4">Book Now</button>
                        </div>
                        <div className="col-xl-6 wow fadeInRight" data-wow-delay="0.4s">
                            <img src="/img/Places/Almora.jpg" className="img-fluid w-100" alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AlmoraTour
import React from 'react';
import TourPackage from './TourPackage';
import TaxiService from './TaxiService';
import ImageGallery from './ImageGallery';
import CountUp from 'react-countup';
import CarouselPage from './CarouselPage';

const HomePage = () => {
    return (
        <>
            <div className="contaoner-fluid">
                <div className="carousel-container" style={{ position: 'relative' }}>
                    <CarouselPage />
                </div>
            </div>

            <div className="container-fluid service py-5">
                <div className="container py-5">
                    <div className="row g-4">
                        <div className="col-sm-12 col-md-6 col-lg-6" data-wow-delay="0.2s">
                            <img src="/img/Collage/collage-1.jpg" alt="" style={{ width: '90%', height: '80%' }} />
                        </div>
                        <div className="col">
                            <div className="row">
                                <div className="col text-center">
                                    <h1><b>Travel is the only thing you</b> <b className='text-primary'>buy that makes you richer</b></h1>
                                    <h6>
                                        Explore the world with our curated tour packages designed to enrich your life with unforgettable experiences. Whether you're seeking adventure, relaxation, or cultural immersion, our travel offerings are crafted to provide you with the journey of a lifetime.</h6>
                                </div>
                            </div>
                            <div className="row p-3" style={{ backgroundColor: '#ecf4f9', margin: '8px', borderRadius: '15px' }}>
                                <div className="col-sm-2 pt-2">
                                    <img src="/img/1.png" className="image-fluid" alt="" style={{ height: '60px' }} />
                                </div>
                                <div className="col text-start">
                                    <h5 className='text-primary'><b>Book With Confident</b></h5>
                                    <h6>Our booking process is simple and secure, ensuring peace of mind as you plan your next adventure. Rest assured that your journey will be in capable hands from start to finish.</h6>
                                </div>
                            </div>
                            <div className="row p-3" style={{ backgroundColor: '#ecf4f9', margin: '8px', borderRadius: '15px' }}>
                                <div className="col-sm-2 pt-2">
                                    <img src="/img/2.jpg" className="image-fluid" alt="" style={{ height: '60px' }} />
                                </div>
                                <div className="col text-start">
                                    <h5 className='text-primary'><b>Freedom to discover</b></h5>
                                    <h6 className='pt-2 text-dark'>
                                        Enjoy the flexibility to explore your destination at your own pace. Our tours offer a blend of guided activities and free time, so you can create your own unique travel experience.</h6>

                                </div>
                            </div>
                            <div className="row p-3" style={{ backgroundColor: '#ecf4f9', margin: '8px', borderRadius: '15px' }}>
                                <div className="col-sm-2 pt-2">
                                    <img src="/img/3.jpg" className="image-fluid" alt="" style={{ height: '60px' }} />
                                </div>
                                <div className="col text-start">
                                    <h5 className='text-primary'><b>Dive into Culture</b></h5>
                                    <h6>
                                        Immerse yourself in local cultures and traditions. Our tours offer authentic experiences that connect you with the heart and soul of each destination.
                                    </h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="row bg-primary text-center p-5">
                    <h3 className='text-white p-5'><b>Featured Packages</b></h3>
                </div>
            </div>

            <TourPackage />
            <TaxiService />
            <div className="container-fluid">
                <div className="row">
                    <div className="col p-5">
                        <div className="row">
                            <div className="col text-center p-5 me-4" style={{ backgroundColor: '#ecf4f9', height: '200px', width: '300px', padding: '10px', margin: '10px', borderRadius: '15px' }}>
                                <h1 className='h1 fw-bold text-primary'>
                                    <CountUp start={0} end={2000} duration={10} separator="," /> +
                                </h1>
                                <p className="text-dark">Tours</p>
                            </div>
                            <div className="col text-center p-5 me-4" style={{ backgroundColor: '#ecf4f9', height: '200px', width: '300px', padding: '10px', margin: '10px', borderRadius: '15px' }}>
                                <h1 className='h1 fw-bold text-primary'>
                                    <CountUp start={0} end={1000} duration={10} separator="," /> +
                                </h1>
                                <p className='text-dark'>Happy Clients</p>
                            </div>
                            <div className="col text-center p-5 me-4" style={{ backgroundColor: '#ecf4f9', height: '200px', width: '300px', padding: '10px', margin: '10px', borderRadius: '15px' }}>
                                <h1 className='h1 fw-bold text-primary'>
                                    <CountUp start={0} end={100} duration={10} separator="," /> +
                                </h1>
                                <p className="text-dark">Yearly Tours</p>
                            </div>
                            <div className="col text-center p-5 me-4" style={{ backgroundColor: '#ecf4f9', height: '200px', width: '300px', padding: '10px', margin: '10px', borderRadius: '15px' }}>
                                <h1 className='h1 fw-bold text-primary'>
                                    <CountUp start={0} end={2000} duration={10} separator="," /> +
                                </h1>
                                <p className="text-dark">Guided Tours Conducted</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="row">
                    <div className="col p-5">
                        <ImageGallery />
                    </div>
                </div>
            </div>
        </>
    )
}

export default HomePage
import React from 'react'
import { Link } from 'react-router-dom'

const RanikhetTour = () => {
    return (
        <>
            <div className="text-center mx-auto pb-5 wow fadeInUp" data-wow-delay="0.2s" style={{ maxWidth: '800px' }}>
                <h4 className="text-primary">Tour Package</h4>
                <h1 className="display-4 mb-4">Ranikhet Munsiyari tour package</h1>
                <p className="mb-0"> Ranikhet and Munsiyari are picturesque hill stations in the Indian state of Uttarakhand. Known for their serene landscapes, panoramic views of the Himalayas, and rich cultural heritage, they offer a perfect retreat for nature lovers and adventure enthusiasts.</p>
            </div>
            <div className="container-fluid faq-section pb-5">
                <div className="container pb-5">
                    <div className="row mt-5 g-5 align-items-center">
                        <div className="col-xl-6 wow fadeInRight" data-wow-delay="0.4s">
                            <div>
                                <h4 className="text-primary">Tour Details</h4>
                                <div className="row g-3">
                                    <div className="col text-primary">
                                        <div className="accordion accordion-flush bg-light" id="accordionFlushExample1">
                                            <div className="accordion-item">
                                                <h2 className="accordion-header">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne1" aria-expanded="false" aria-controls="flush-collapseOne1">
                                                        Day 1
                                                    </button>
                                                </h2>
                                                <div id="flush-collapseOne1" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample1">
                                                    <div className="accordion-body">
                                                        kathgodam to Ranikhet on the way site seen kanchi dham temple . golf course. Chaubatiya garden Kalika temple museum night Ranikhet
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion accordion-flush mt-2 bg-light" id="accordionFlushExample2">
                                            <div className="accordion-item">
                                                <h2 className="accordion-header">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne2" aria-expanded="false" aria-controls="flush-collapseOne2">
                                                        Day 2
                                                    </button>
                                                </h2>
                                                <div id="flush-collapseOne2" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample2">
                                                    <div className="accordion-body">
                                                        Ranikhet to kausani kausani local site seen tea garden .Gandhi aashram .sowal factory.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion accordion-flush bg-light mt-2" id="accordionFlushExample3">
                                            <div className="accordion-item">
                                                <h2 className="accordion-header">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne3" aria-expanded="false" aria-controls="flush-collapseOne3">
                                                        Day 3
                                                    </button>
                                                </h2>
                                                <div id="flush-collapseOne3" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample3">
                                                    <div className="accordion-body">
                                                        Kausani to chakori on the way Baijnath temple. Bagnath  temple.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion accordion-flush mt-2 bg-light" id="accordionFlushExample4">
                                            <div className="accordion-item">
                                                <h2 className="accordion-header">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne4" aria-expanded="false" aria-controls="flush-collapseOne4">
                                                        Day 4
                                                    </button>
                                                </h2>
                                                <div id="flush-collapseOne4" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample4">
                                                    <div className="accordion-body">
                                                        Chakori to munsyari on the way ramganga river birthi fall.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion accordion-flush mt-2 bg-light" id="accordionFlushExample4">
                                            <div className="accordion-item">
                                                <h2 className="accordion-header">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne4" aria-expanded="false" aria-controls="flush-collapseOne4">
                                                        Day 5
                                                    </button>
                                                </h2>
                                                <div id="flush-collapseOne4" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample4">
                                                    <div className="accordion-body">
                                                        Munsyari local site scene
                                                        <ul>
                                                            <li>Kalia top</li>
                                                            <li>Nanda Devi</li>
                                                            <li>temple</li>
                                                            <li>museum</li>
                                                            <li>thamri kund</li>
                                                            <li>Mahesh kund</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion accordion-flush mt-2 bg-light" id="accordionFlushExample4">
                                            <div className="accordion-item">
                                                <h2 className="accordion-header">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne4" aria-expanded="false" aria-controls="flush-collapseOne4">
                                                        Day 6
                                                    </button>
                                                </h2>
                                                <div id="flush-collapseOne4" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample4">
                                                    <div className="accordion-body">
                                                        Munsyari to Almora on the way sightseeing.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion accordion-flush mt-2 bg-light" id="accordionFlushExample4">
                                            <div className="accordion-item">
                                                <h2 className="accordion-header">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne4" aria-expanded="false" aria-controls="flush-collapseOne4">
                                                        Day 7
                                                    </button>
                                                </h2>
                                                <div id="flush-collapseOne4" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample4">
                                                    <div className="accordion-body">
                                                        Almora to kathgodam drop on the way bheemtal nakuchiatal saat tal .
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Link to='/car-book-page'><button className="btn btn-primary rounded-pill py-3 px-5 mt-4">Book Now</button></Link>
                        </div>
                        <div className="col-xl-6 wow fadeInRight" data-wow-delay="0.4s">
                            <img src="/img/Collage/RaniKhet-collage.png" className="img-fluid w-100 rounded" alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default RanikhetTour
import React, { useRef } from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.min.css';
import 'owl.carousel/dist/assets/owl.theme.default.min.css';

const CarouselPage = () => {
    const carouselRef = useRef(null);
    const options = {
        loop: true,
        margin: 1,
        items: 1,
        autoplay: true,
        nav: true, // Enable navigation dots
        dots: false,
    };

    const goToPrev = () => {
        if (carouselRef.current) {
            carouselRef.current.prev();
        }
    };

    const goToNext = () => {
        if (carouselRef.current) {
            carouselRef.current.next();
        }
    };

    return (
        <>
            <OwlCarousel
                ref={carouselRef}
                className='owl-theme'
                {...options}
            >
                <div className='item'>
                    <div className="calrousel-img" style={{ position: 'relative', height: '700px', width: '100%' }}>
                        <img src="/img/slider-3.jpg" className="img-fluid" alt="" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                        <div className="carousel-text">
                            <h1 className="carousel-heading text-white" >Welcome to Hill Heart Taxi</h1>
                            <p className="carousel-description">Your Trustworthy Partner</p>
                        </div>
                    </div>
                </div>
                <div className='item'>
                    <div className="calrousel-img" style={{ position: 'relative', height: '700px' }}>
                        <img src="/img/slider-2.jpg" className="img-fluid" alt="" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                        <div className="carousel-text">
                            <h1 className="carousel-heading text-white">Welcome to Hill Heart Taxi</h1>
                            <p className="carousel-description">Passion and Dedication</p>
                        </div>
                    </div>
                </div>
                <div className='item'>
                    <div className="calrousel-img text-white" style={{ position: 'relative', height: '700px' }}>
                        <img src="/img/slider-1.jpg" className="img-fluid" alt="" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                        <div className="carousel-text">
                            <h1 className="carousel-heading text-white">Welcome to Hill Heart Taxi</h1>
                            <p className="carousel-description">Reliable and Fast Transport</p>
                        </div>
                    </div>
                </div>
            </OwlCarousel>

            {/* Custom Navigation Buttons */}
            <button className="carousel-button prev" onClick={goToPrev}>
                <i className="fas fa-chevron-left"></i>
            </button>
            <button className="carousel-button next" onClick={goToNext}>
                <i className="fas fa-chevron-right"></i>
            </button>

            {/* Internal CSS */}
            <style jsx>{`
                .carousel-text {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    color: white;
                    text-align: center;
                    z-index: 10;
                    background: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
                    padding: 20px; /* Add padding for spacing */
                    border-radius: 10px; /* Optional: Add rounded corners */
                }

                .carousel-heading {
                    font-family: 'Roboto', serif;
                    font-size: 5rem;
                    margin-bottom: 10px;
                }

                .carousel-description {
                    font-family: 'Roboto', sans-serif;
                    font-size: 2rem;
                }

                .carousel-button {
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    background: rgba(0, 0, 0, 0.5);
                    color: white;
                    border: none;
                    padding: 10px;
                    border-radius: 50%;
                    cursor: pointer;
                    z-index: 20;
                    font-size: 1.5rem;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                .carousel-button.prev {
                    left: 10px;
                }

                .carousel-button.next {
                    right: 10px;
                }

                .carousel-button i {
                    margin: 0;
                }
            `}</style>
        </>
    );
}

export default CarouselPage;

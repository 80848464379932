import React from 'react'
import { Link } from 'react-router-dom'

const CharDham = () => {
  return (
    <>
      <div className="text-center mx-auto pb-5 wow fadeInUp" data-wow-delay="0.2s" style={{ maxWidth: '800px' }}>
        <h4 className="text-primary">Tour Package</h4>
        <h1 className="display-4 mb-4">Char dham Yatra package</h1>
        <p className="mb-0"> Experience a transformative journey through the Char Dham, the four sacred shrines nestled in the majestic Himalayas of Uttarakhand. This pilgrimage covers Yamunotri, Gangotri, Kedarnath, and Badrinath, each representing a significant aspect of Hindu spirituality. Our Char Dham Tour offers a spiritual adventure that includes breathtaking landscapes, sacred rituals, and a deep connection with the divine.
        </p>
      </div>
      <div className="container-fluid faq-section pb-5">
        <div className="container pb-5">
          <div className="row mt-5 g-5 align-items-center">
            <div className="col-xl-6 wow fadeInRight" data-wow-delay="0.4s">
              <div>
                <h4 className="text-primary">Tour Details</h4>
                <div className="row g-3">
                  <div className="col text-primary">
                    <div className="accordion accordion-flush bg-light" id="accordionFlushExample1">
                      <div className="accordion-item">
                        <h2 className="accordion-header">
                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne1" aria-expanded="false" aria-controls="flush-collapseOne1">
                            Day 1
                          </button>
                        </h2>
                        <div id="flush-collapseOne1" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample1">
                          <div className="accordion-body">
                            Haridwar to barkot night stay.
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="accordion accordion-flush mt-2 bg-light" id="accordionFlushExample2">
                      <div className="accordion-item">
                        <h2 className="accordion-header">
                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne2" aria-expanded="false" aria-controls="flush-collapseOne2">
                            Day 2
                          </button>
                        </h2>
                        <div id="flush-collapseOne2" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample2">
                          <div className="accordion-body">
                            Barkot to yamunotri temple return barkot
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="accordion accordion-flush bg-light mt-2" id="accordionFlushExample3">
                      <div className="accordion-item">
                        <h2 className="accordion-header">
                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne3" aria-expanded="false" aria-controls="flush-collapseOne3">
                            Day 3
                          </button>
                        </h2>
                        <div id="flush-collapseOne3" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample3">
                          <div className="accordion-body">
                            Barkot to Uttarkashi to Gangotri Dham night stay Uttarkashi.
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="accordion accordion-flush mt-2 bg-light" id="accordionFlushExample4">
                      <div className="accordion-item">
                        <h2 className="accordion-header">
                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne4" aria-expanded="false" aria-controls="flush-collapseOne4">
                            Day 4
                          </button>
                        </h2>
                        <div id="flush-collapseOne4" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample4">
                          <div className="accordion-body">
                            Uttarkashi to sonpryag night stay.
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="accordion accordion-flush mt-2 bg-light" id="accordionFlushExample4">
                      <div className="accordion-item">
                        <h2 className="accordion-header">
                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne4" aria-expanded="false" aria-controls="flush-collapseOne4">
                            Day 5
                          </button>
                        </h2>
                        <div id="flush-collapseOne4" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample4">
                          <div className="accordion-body">
                            Sonpryag to kedarnath temple track night Kedarnath night stay.
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="accordion accordion-flush mt-2 bg-light" id="accordionFlushExample4">
                      <div className="accordion-item">
                        <h2 className="accordion-header">
                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne4" aria-expanded="false" aria-controls="flush-collapseOne4">
                            Day 6
                          </button>
                        </h2>
                        <div id="flush-collapseOne4" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample4">
                          <div className="accordion-body">
                            Kedarnath temple to return Sonparayag night stay.
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="accordion accordion-flush mt-2 bg-light" id="accordionFlushExample4">
                      <div className="accordion-item">
                        <h2 className="accordion-header">
                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne4" aria-expanded="false" aria-controls="flush-collapseOne4">
                            Day 7
                          </button>
                        </h2>
                        <div id="flush-collapseOne4" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample4">
                          <div className="accordion-body">
                            Joshi math to Badrinath temple night stay.
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="accordion accordion-flush mt-2 bg-light" id="accordionFlushExample4">
                      <div className="accordion-item">
                        <h2 className="accordion-header">
                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne4" aria-expanded="false" aria-controls="flush-collapseOne4">
                            Day 8
                          </button>
                        </h2>
                        <div id="flush-collapseOne4" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample4">
                          <div className="accordion-body">
                            Badrinath temple darshan after devpryag.
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="accordion accordion-flush mt-2 bg-light" id="accordionFlushExample4">
                      <div className="accordion-item">
                        <h2 className="accordion-header">
                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne4" aria-expanded="false" aria-controls="flush-collapseOne4">
                            Day 9
                          </button>
                        </h2>
                        <div id="flush-collapseOne4" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample4">
                          <div className="accordion-body">
                            Devpryag to Rishikesh.
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="accordion accordion-flush mt-2 bg-light" id="accordionFlushExample4">
                      <div className="accordion-item">
                        <h2 className="accordion-header">
                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne4" aria-expanded="false" aria-controls="flush-collapseOne4">
                            Day 10
                          </button>
                        </h2>
                        <div id="flush-collapseOne4" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample4">
                          <div className="accordion-body">
                            Rishikesh locel site seen and haridwar.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Link to='/car-book-page'><button className="btn btn-primary rounded-pill py-3 px-5 mt-4">Book Now</button></Link>
            </div>
            <div className="col-xl-6 wow fadeInRight" data-wow-delay="0.4s">
              <img src="/img/Places/nainital-lake.jpg" className="img-fluid w-100" alt="" />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default CharDham
import React from 'react'
import { Link } from 'react-router-dom'
import CountUp from 'react-countup';
const AboutUs = () => {
    return (
        <>
            <div className="container-fluid about py-5">
                <div className="container py-5">
                    <div className="text-center mx-auto pb-5 wow fadeInUp" data-wow-delay="0.2s" style={{ maxWidth: '800px' }}>
                        <h4 className="text-primary">About Us</h4>
                        <h1 className="display-6 mb-4">Meet the Experts Behind Your Unforgettable Journeys</h1>
                        <p className="mb-0 text-dark">Our passionate team of guides ensures every experience is unique and memorable.</p>
                    </div>

                    <div className="row g-5">
                        <div className="col-xl-6 wow fadeInLeft" data-wow-delay="0.2s">
                            <div className="about-item-content bg-white rounded p-5 h-100">
                                <h3 className="text-primary display-6">We Are Operating In All Across India</h3>
                                <p className='text-dark'>Hill Heart Taxi is the car rental provider in Kumaon Uttarakhand India. We offer road trip packages at affordable price and cheapest car fare deals. Family trip by car, Hill station trip, Pilgrimage car tour for religious sightseeing etc.available car hire packages for getting the best experiences with a stress-free journey. We are providing the call in service from your doorstep and you can enjoy during the travelling across north india. We having good number of our own vehicle , and most important we have a very well mannered, behaved and professional squad of drivers. Our drivers are well trained for hill area driving and expert in safety measurement as per hill norms for the driving as per govt. of Uttarakhand. We provide economic as well as luxury vehicles in very nominal charges.</p>
                                <Link to='/car-book-page'><button className="btn btn-primary rounded-pill py-3 px-5 mt-4">Book Now</button></Link>
                            </div>
                        </div>
                        <div className="col-xl-6 wow fadeInRight" data-wow-delay="0.2s">
                            <div className="bg-white rounded p-5 h-100">
                                <div className="row g-4 justify-content-center">
                                    <div className="col-12">
                                        <div className="rounded bg-light">
                                            <img src="/img/Collage/collage-2.jpg" className="img-fluid rounded w-100 h-100" alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <div className="container-fluid faq-section pb-5">
                <div className="container pb-5">
                    <div className="row g-5 align-items-center">
                        <div className="col-xl-6 wow fadeInLeft" data-wow-delay="0.2s">
                            <div className="h-100">
                                <div className="mb-5 text-center">
                                    <h2 className="text-primary display-6">USP</h2>
                                    <p className="mb-0 text-dark">Our Usp to provide all customer provide siteseeing in all over uttarakhand for very nominal price</p>
                                </div>
                                <div className="accordion" id="accordionExample">
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="headingOne">
                                            <button className="accordion-button border-0" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                Who Are you?
                                            </button>
                                        </h2>
                                        <div id="collapseOne" className="accordion-collapse collapse show active" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                            <div className="accordion-body rounded">
                                                <p className='text-dark'>I am your Travel advisor and advise to all tourist best places for tour sieghtseeing .</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="headingTwo">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                Payment Term
                                            </button>
                                        </h2>
                                        <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                            <div className="accordion-body">
                                                <p className='text-dark'>30 % Booking Amount and rest amount you can pay on tour ..</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="headingThree">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                What is Taxi Cost ?
                                            </button>
                                        </h2>
                                        <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                            <div className="accordion-body">
                                                <p className='text-dark'>Taxi cost dipend on  your sightseeing and perday taxi rent available on your requirement in which taxi you require</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="headingThree">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                What we do?
                                            </button>
                                        </h2>
                                        <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                            <div className="accordion-body">
                                                <p className='text-dark'>You do the call our customer care number take information for your tour package after that you book your tour.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 wow fadeInRight" data-wow-delay="0.4s">
                            <img src="/img/Image-Gallery/gallery-16.jpg" className="img-fluid w-100" style={{ height: '430px' }} alt="" />
                        </div>
                    </div>
                    <div className="row mt-5 g-5 align-items-center">
                        <div className="col-xl-6 wow fadeInRight" data-wow-delay="0.4s">
                            <img src="/img/Collage/collage-1.jpg" className="img-fluid w-100" style={{ height: '450px' }} alt="" />
                        </div>
                        <div className="col-xl-6 wow fadeInRight" data-wow-delay="0.4s">
                            <h3 className='display-6 text-primary'><b>Explore All Tour Packages</b></h3>
                            <p className='pt-3 text-dark'>Hill Hear Taxi provide tour package and taxi in all over Uttarakhand .
                                Provide taxi hotel sightseeing and all type cab .like
                                Desire Ertigga sedon </p>
                            <Link to='/tour-package'><button className="btn btn-primary rounded-pill py-3 px-5 mt-4">Explore Packages</button></Link>
                        </div>

                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="row">
                    <div className="col p-5">
                        <div className="row">
                            <div className="col text-center p-5 me-4" style={{ backgroundColor: '#ecf4f9', height: '200px', width: '300px', padding: '10px', margin: '10px', borderRadius: '15px' }}>
                                <h1 className='h1 fw-bold text-primary'>
                                    <CountUp start={0} end={2000} duration={10} separator="," /> +
                                </h1>
                                <p className="text-dark">Tours</p>
                            </div>
                            <div className="col text-center p-5 me-4" style={{ backgroundColor: '#ecf4f9', height: '200px', width: '300px', padding: '10px', margin: '10px', borderRadius: '15px' }}>
                                <h1 className='h1 fw-bold text-primary'>
                                    <CountUp start={0} end={1000} duration={10} separator="," /> +
                                </h1>
                                <p className='text-dark'>Happy Clients</p>
                            </div>
                            <div className="col text-center p-5 me-4" style={{ backgroundColor: '#ecf4f9', height: '200px', width: '300px', padding: '10px', margin: '10px', borderRadius: '15px' }}>
                                <h1 className='h1 fw-bold text-primary'>
                                    <CountUp start={0} end={100} duration={10} separator="," /> +
                                </h1>
                                <p className="text-dark">Yearly Tours</p>
                            </div>
                            <div className="col text-center p-5 me-4" style={{ backgroundColor: '#ecf4f9', height: '200px', width: '300px', padding: '10px', margin: '10px', borderRadius: '15px' }}>
                                <h1 className='h1 fw-bold text-primary'>
                                    <CountUp start={0} end={2000} duration={10} separator="," /> +
                                </h1>
                                <p className="text-dark">Guided Tours Conducted</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default AboutUs
import React from 'react'
import { Link } from 'react-router-dom'

const KausaniTour = () => {
  return (
    <>
      <div className="text-center mx-auto pb-5 wow fadeInUp" data-wow-delay="0.2s" style={{ maxWidth: '800px' }}>
        <h4 className="text-primary">Tour Package</h4>
        <h1 className="display-4 mb-4">Kausani Tour Packages</h1>
        <p className="mb-0">  Escape to the serene hill station of Kasauni, nestled in the heart of Uttarakhand. Known for its panoramic views of the Himalayan peaks and lush green valleys, Kasauni offers a peaceful retreat from the hustle and bustle of city life. Our Kasauni Tour Package is designed to provide you with an immersive experience in nature, including scenic vistas, local culture, and relaxing moments in one of the most beautiful places in the region.
        </p>
      </div>
      <div className="container-fluid faq-section pb-5">
        <div className="container pb-5">
          <div className="row mt-5 g-5 align-items-center">
            <div className="col-xl-6 wow fadeInRight" data-wow-delay="0.4s">
              <div>
                <h4 className="text-primary">Tour Details</h4>
                <div className="row g-3">
                  <div className="col text-primary">
                    <div className="accordion accordion-flush bg-light" id="accordionFlushExample1">
                      <div className="accordion-item">
                        <h2 className="accordion-header">
                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne1" aria-expanded="false" aria-controls="flush-collapseOne1">
                            Day 1
                          </button>
                        </h2>
                        <div id="flush-collapseOne1" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample1">
                          <div className="accordion-body">
                            kathgodam to Ranikhet night stay
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="accordion accordion-flush mt-2 bg-light" id="accordionFlushExample2">
                      <div className="accordion-item">
                        <h2 className="accordion-header">
                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne2" aria-expanded="false" aria-controls="flush-collapseOne2">
                            Day 2
                          </button>
                        </h2>
                        <div id="flush-collapseOne2" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample2">
                          <div className="accordion-body">
                            Ranikhet to kausani site seen Ranikhet.
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="accordion accordion-flush bg-light mt-2" id="accordionFlushExample3">
                      <div className="accordion-item">
                        <h2 className="accordion-header">
                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne3" aria-expanded="false" aria-controls="flush-collapseOne3">
                            Day 3
                          </button>
                        </h2>
                        <div id="flush-collapseOne3" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample3">
                          <div className="accordion-body">
                            Kausani local site seen Baijnath temple. Tee garden. Ana shakti ashram .
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="accordion accordion-flush mt-2 bg-light" id="accordionFlushExample4">
                      <div className="accordion-item">
                        <h2 className="accordion-header">
                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne4" aria-expanded="false" aria-controls="flush-collapseOne4">
                            Day 4
                          </button>
                        </h2>
                        <div id="flush-collapseOne4" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample4">
                          <div className="accordion-body">
                            Kausani to kathgodam on the way almora.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Link to='/car-book-page'><button className="btn btn-primary rounded-pill py-3 px-5 mt-4">Book Now</button></Link>
            </div>
            <div className="col-xl-6 wow fadeInRight" data-wow-delay="0.4s">
              <img src="/img/Places/Kasauni.jpg" className="img-fluid w-100" alt="" />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default KausaniTour
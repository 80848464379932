import React, { useState } from 'react';

const BookingPage = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        subject: '',
        message: ''
    });

    const handleChange = (e) => {
        const { id, value } = e.target;
        setFormData({ ...formData, [id]: value });
    };

    const handleEmailClick = (e) => {
        e.preventDefault();
        const { name, email, phone, subject, message } = formData;
        const mailtoURL = `mailto:vinodsbisht2492@gmail.com?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(
            `Name: ${name}\nEmail: ${email}\nPhone: ${phone}\nSubject: ${subject}\nMessage: ${message}`
        )}`;
        window.location.href = mailtoURL;

        // Optionally reset form fields
        setFormData({
            name: '',
            email: '',
            phone: '',
            subject: '',
            message: ''
        });
    };

    const handleWhatsappClick = (e) => {
        e.preventDefault();
        const { name, email, phone, subject, message } = formData;
        const formattedMessage = `*Contact Form Submission*\n\n` +
            `*Name:* ${name}\n` +
            `*Email:* ${email}\n` +
            `*Phone:* ${phone}\n` +
            `*Subject:* ${subject}\n` +
            `*Message:* ${message}`;

        const encodedMessage = encodeURIComponent(formattedMessage);
        const whatsappURL = `https://wa.me/7617712274?text=${encodedMessage}`;
        window.location.href = whatsappURL;

        // Optionally reset form fields
        setFormData({
            name: '',
            email: '',
            phone: '',
            subject: '',
            message: ''
        });
    };

    return (
        <div className="container-fluid bg-light about py-5">
            <div className="row">
                <div className="col">
                    <div className="container py-5">
                        <div className="text-center mx-auto pb-5 wow fadeInUp" data-wow-delay="0.2s" style={{ maxWidth: '800px' }}>
                            <h1 className="display-4 text-dark">Book Car</h1>
                            <p className="text-primary mb-4">
                                Our car booking service provides a seamless experience for selecting the perfect vehicle for your needs. Whether you need a car for a day trip, a weekend getaway, or a special event, we have a wide range of vehicles to choose from. Our team is dedicated to ensuring you have a comfortable and enjoyable experience.
                            </p>
                        </div>
                        <div className="row g-5">
                            <div className="col-xl-6 wow fadeInLeft" data-wow-delay="0.2s">
                                <div className="contact-img d-flex justify-content-center">
                                    <div className="contact-img-inner pt-5">
                                        <img src="/img/Car/ciaz-png.png" className="img-fluid" style={{ width: '100%' }} alt="Car" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-6 wow fadeInRight" data-wow-delay="0.4s">
                                <form>
                                    <div className="row g-3">
                                        <div className="col-lg-12 col-xl-6">
                                            <div className="form-floating">
                                                <input
                                                    type="text"
                                                    className="form-control border-0"
                                                    id="name"
                                                    placeholder="Your Name"
                                                    value={formData.name}
                                                    onChange={handleChange}
                                                    required
                                                />
                                                <label htmlFor="name">Your Name</label>
                                            </div>
                                        </div>
                                        <div className="col-lg-12 col-xl-6">
                                            <div className="form-floating">
                                                <input
                                                    type="email"
                                                    className="form-control border-0"
                                                    id="email"
                                                    placeholder="Your Email"
                                                    value={formData.email}
                                                    onChange={handleChange}
                                                    required
                                                />
                                                <label htmlFor="email">Your Email</label>
                                            </div>
                                        </div>
                                        <div className="col-lg-12 col-xl-12">
                                            <div className="form-floating">
                                                <input
                                                    type="text"
                                                    className="form-control border-0"
                                                    id="phone"
                                                    placeholder="Phone"
                                                    value={formData.phone}
                                                    onChange={handleChange}
                                                    required
                                                />
                                                <label htmlFor="phone">Your Phone</label>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="form-floating">
                                                <input
                                                    type="text"
                                                    className="form-control border-0"
                                                    id="subject"
                                                    placeholder="Subject"
                                                    value={formData.subject}
                                                    onChange={handleChange}
                                                    required
                                                />
                                                <label htmlFor="subject">Subject</label>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="form-floating">
                                                <textarea
                                                    className="form-control border-0"
                                                    placeholder="Leave a message here"
                                                    id="message"
                                                    style={{ height: '120px' }}
                                                    value={formData.message}
                                                    onChange={handleChange}
                                                    required
                                                ></textarea>
                                                <label htmlFor="message">Message</label>
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div className="row">
                                                <div className="col">
                                                    <button onClick={handleEmailClick} className="btn btn-primary py-3 pe-3">
                                                        <i className='fas fa-envelope text-email'></i> Book Now By Email
                                                    </button>
                                                </div>
                                                <div className="col text-end">
                                                    <button onClick={handleWhatsappClick} className="btn btn-primary py-3 pe-3">
                                                        <i className='fab fa-whatsapp text-white'></i> Book Now By Whatsapp
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BookingPage;
import HomePage from './Components/HomePage';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Layout from './Components/Layout';
import AboutUs from './Components/AboutUs';
import ContactUs from './Components/ContactUs';
import Blog from './Components/Blog';
import TaxiService from './Components/TaxiService';
import TourPackage from './Components/TourPackage';
import BookingPage from './Components/BookingPage';
import NainitalTour from './Components/NainitalTour';
import JimCarbettParkTour from './Components/JimCarbettParkTour';
import HaridwarTour from './Components/HaridwarTour';
import CharDham from './Components/CharDham';
import KausaniTour from './Components/KausaniTour';
import JageswarDham from './Components/JageswarDham';
import AlmoraTour from './Components/AlmoraTour';
import RanikhetTour from './Components/RanikhetTour';
import KainchiDhamTour from './Components/KainchiDhamTour';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Layout />}>
          <Route index element={<HomePage />} />
          <Route path='/about' element={<AboutUs />} />
          <Route path='/contact' element={<ContactUs />} />
          <Route path='/blog' element={<Blog />} />
          <Route path='/taxi-service' element={<TaxiService />} />
          <Route path='/tour-package' element={<TourPackage />} />
          <Route path='/car-book-page' element={<BookingPage />} />
          <Route path='/tour-package/nainital' element={<NainitalTour />} />
          <Route path='/tour-package/JimCarbett' element={<JimCarbettParkTour />} />
          <Route path='/tour-package/haridwar' element={<HaridwarTour />} />
          <Route path='/tour-package/chardham' element={<CharDham />} />
          <Route path='/tour-package/kausani' element={<KausaniTour />} />
          <Route path='/tour-package/jageswar-dham' element={<JageswarDham />} />
          <Route path='/tour-package/almora' element={<AlmoraTour />} />
          <Route path='/tour-package/Ranikhet-Munsiyari' element={<RanikhetTour />} />
          <Route path='/tour-package/kaichi-dham' element={<KainchiDhamTour />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;

import React from 'react'
import { Link } from 'react-router-dom'

const Footer = () => {
    return (
        <>
            <div className="container-fluid footer py-5 wow fadeIn" data-wow-delay="0.2s">
                <div className="container py-5">
                    <div className="row g-5">
                        <div className="col-xl-9">
                            <div className="mb-5">
                                <div className="row g-4">
                                    <div className="col-md-6 col-lg-6 col-xl-5">
                                        <div className="footer-item">
                                            <a href="index.html" className="p-0">
                                                <img src="/img/HillHeartLogo-.png" style={{height:'100px',width:'250px'}} alt="" />
                                            </a>
                                            <p className="text-white mb-4">Full Fill Your Journery in Uttarakahnd </p>
                                            <div className="row">
                                                <div className="col">
                                                    <Link to='/car-book-page'><button className="btn btn-primary rounded-pill py-3 px-5 mt-4">Book Now</button></Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-lg-6 col-xl-3">
                                        <div className="footer-item text-white">
                                            <h4 className="text-white mb-4">Useful Links</h4>
                                            <Link to="/"><i className="fas fa-angle-right me-2"></i> Home</Link>
                                            <Link to="/about"><i className="fas fa-angle-right me-2"></i> About Us</Link>
                                            <Link to="/blog"><i className="fas fa-angle-right me-2"></i> Guide</Link>
                                            <Link to="/contact"><i className="fas fa-angle-right me-2"></i> Contact Us</Link>
                                            <Link to="/tour-package"><i className="fas fa-angle-right me-2"></i> Tour Package</Link>
                                            <Link to="/taxi-service"><i className="fas fa-angle-right me-2"></i> Taxi Service</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-xl-3">
                            <div className="footer-item text-white">
                                <p><i className='fa fa-envelope'></i><a href="mailto:vinodsbisht2492@gmail.com"> &nbsp;vinodsbisht2492@gmail.com</a> </p>
                                <p><i className='fa fa-phone'></i><a href="tel:7617712274"> &nbsp;7617712274</a></p>
                                <p><i className='fa fa-map'></i> &nbsp; Haldwani, NH-109, near Bus Stand Road, Banbhoolpura, Haldwani, Uttarakhand, 263139</p>
                            </div>
                            <div className="footer-btn d-flex">
                                <a className="btn p-0 text-white me-3" href="https://www.facebook.com/vinod.bisht.56211?mibextid=ZbWKwL"><i className="fab fa-facebook-f"></i></a>
                                <a className="btn p-0 text-white me-3" href="#"><i className="fab fa-instagram"></i></a>
                                <a className="btn p-0 text-white me-0" href="https://www.youtube.com/@hillstaxivloguk01"><i className="fab fa-youtube"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid  py-4">
                <div className="container">
                    <div className="row g-4 align-items-center">
                        <div className="col-md-6 text-center text-md-end mb-md-0">
                            <span className="text-body text-black">Hill Heart Taxi</span>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Footer
import React from 'react'
import { Link } from 'react-router-dom'

const NainitalTour = () => {
  return (
    <>
      <div className="text-center mx-auto pb-5 wow fadeInUp" data-wow-delay="0.2s" style={{ maxWidth: '800px' }}>
        <h4 className="text-primary">Tour Package</h4>
        <h1 className="display-4 mb-4">Nainital tour package</h1>
        <p className="mb-0"> Embark on a mesmerizing journey to Nainital, a stunning hill station nestled in the Kumaon region of Uttarakhand. Known for its scenic beauty, pleasant weather, and serene environment, Nainital offers a perfect escape from the hustle and bustle of city life. Our Nainital Tour Package is designed to give you an unforgettable experience, whether you're looking for relaxation or adventure.
        </p>
      </div>
      <div className="container-fluid faq-section pb-5">
        <div className="container pb-5">
          <div className="row mt-5 g-5 align-items-center">
            <div className="col-xl-6 wow fadeInRight" data-wow-delay="0.4s">
              <div>
                <h4 className="text-primary">Tour Details</h4>
                <div className="row g-3">
                  <div className="col text-primary">
                    <div className="accordion accordion-flush bg-light" id="accordionFlushExample1">
                      <div className="accordion-item">
                        <h2 className="accordion-header">
                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne1" aria-expanded="false" aria-controls="flush-collapseOne1">
                            Day 1
                          </button>
                        </h2>
                        <div id="flush-collapseOne1" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample1">
                          <div className="accordion-body">
                            Nainital site seen Naina Devi temple. Eco cave garden. Tiffin top. Himalaya Darshan. Boating .Nainital zoo. Mall road.
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="accordion accordion-flush mt-2 bg-light" id="accordionFlushExample2">
                      <div className="accordion-item">
                        <h2 className="accordion-header">
                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne2" aria-expanded="false" aria-controls="flush-collapseOne2">
                            Day 2
                          </button>
                        </h2>
                        <div id="flush-collapseOne2" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample2">
                          <div className="accordion-body">
                            Lake tour Bhimtal. saat tal . naukuchiatal .water sporting. paragliding.
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="accordion accordion-flush bg-light mt-2" id="accordionFlushExample3">
                      <div className="accordion-item">
                        <h2 className="accordion-header">
                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne3" aria-expanded="false" aria-controls="flush-collapseOne3">
                            Day 3
                          </button>
                        </h2>
                        <div id="flush-collapseOne3" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample3">
                          <div className="accordion-body">
                            Muktesh war temple. Apple garden. Tee garden
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="accordion accordion-flush mt-2 bg-light" id="accordionFlushExample4">
                      <div className="accordion-item">
                        <h2 className="accordion-header">
                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne4" aria-expanded="false" aria-controls="flush-collapseOne4">
                            Day 4
                          </button>
                        </h2>
                        <div id="flush-collapseOne4" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample4">
                          <div className="accordion-body">
                            kainchi Dham temple. Fork point
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Link to='/car-book-page'><button className="btn btn-primary rounded-pill py-3 px-5 mt-4">Book Now</button></Link>
            </div>
            <div className="col-xl-6 wow fadeInRight" data-wow-delay="0.4s">
              <img src="/img/Places/nainital-lake.jpg" className="img-fluid w-100" alt="" />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default NainitalTour
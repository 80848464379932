import React from 'react'
import { Link } from 'react-router-dom'

const JageswarDham = () => {
  return (
    <>
      <div className="text-center mx-auto pb-5 wow fadeInUp" data-wow-delay="0.2s" style={{ maxWidth: '800px' }}>
        <h4 className="text-primary">Tour Package</h4>
        <h1 className="display-4 mb-4">Jageshwar Dham yatra Packages</h1>
        <p className="mb-0"> Immerse yourself in the tranquility of Jageshwar Dham with our 2 nights and 3 days Yatra package. Nestled in the scenic Kumaon region of Uttarakhand, Jageshwar Dham is renowned for its ancient temples dedicated to Lord Shiva. This package offers a perfect blend of spirituality, history, and natural beauty, providing a rejuvenating experience in one of the most revered pilgrimage sites in the Himalayas.
        </p>
      </div>
      <div className="container-fluid faq-section pb-5">
        <div className="container pb-5">
          <div className="row mt-5 g-5 align-items-center">
            <div className="col-xl-6 wow fadeInRight" data-wow-delay="0.4s">
              <div>
                <h4 className="text-primary">Tour Details</h4>
                <div className="row g-3">
                  <div className="col text-primary">
                    <div className="accordion accordion-flush bg-light" id="accordionFlushExample1">
                      <div className="accordion-item">
                        <h2 className="accordion-header">
                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne1" aria-expanded="false" aria-controls="flush-collapseOne1">
                            Day 1
                          </button>
                        </h2>
                        <div id="flush-collapseOne1" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample1">
                          <div className="accordion-body">
                            Kathgodam to jageshwar temple
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="accordion accordion-flush mt-2 bg-light" id="accordionFlushExample2">
                      <div className="accordion-item">
                        <h2 className="accordion-header">
                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne2" aria-expanded="false" aria-controls="flush-collapseOne2">
                            Day 2
                          </button>
                        </h2>
                        <div id="flush-collapseOne2" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample2">
                          <div className="accordion-body">
                            Jageshwar to dol ashram bell temple.
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="accordion accordion-flush bg-light mt-2" id="accordionFlushExample3">
                      <div className="accordion-item">
                        <h2 className="accordion-header">
                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne3" aria-expanded="false" aria-controls="flush-collapseOne3">
                            Day 3
                          </button>
                        </h2>
                        <div id="flush-collapseOne3" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample3">
                          <div className="accordion-body">
                            Kainchi Dham temple and drop
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="accordion accordion-flush mt-2 bg-light" id="accordionFlushExample4">
                      <div className="accordion-item">
                        <h2 className="accordion-header">
                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne4" aria-expanded="false" aria-controls="flush-collapseOne4">
                            Day 4
                          </button>
                        </h2>
                        <div id="flush-collapseOne4" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample4">
                          <div className="accordion-body">
                            Mussoorie to Rishikesh on the way site seeing .night stay
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="accordion accordion-flush mt-2 bg-light" id="accordionFlushExample4">
                      <div className="accordion-item">
                        <h2 className="accordion-header">
                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne4" aria-expanded="false" aria-controls="flush-collapseOne4">
                            Day 5
                          </button>
                        </h2>
                        <div id="flush-collapseOne4" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample4">
                          <div className="accordion-body">
                            Rishikesh local site seen Neelkanth Mahadev temple. All activities river rafting. Ram jhoola Laxman jhoola. Ganga arti.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Link to='/car-book-page'><button className="btn btn-primary rounded-pill py-3 px-5 mt-4">Book Now</button></Link>
            </div>
            <div className="col-xl-6 wow fadeInRight" data-wow-delay="0.4s">
              <img src="/img/Places/Jageswar-dham.jpg" className="img-fluid w-100" alt="" />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default JageswarDham
import React from 'react'
import { Link } from 'react-router-dom'

const KainchiDhamTour = () => {
    return (
        <>
            <div className="text-center mx-auto pb-5 wow fadeInUp" data-wow-delay="0.2s" style={{ maxWidth: '800px' }}>
                <h4 className="text-primary">Tour Package</h4>
                <h1 className="display-4 mb-4">Kaichi Dham  Tour Packages</h1>
                <p className="mb-0">  Kaichi Dham is a revered pilgrimage site nestled in the serene landscapes of Uttarakhand, India. The temple complex is dedicated to the revered saint, Kedarnath Ji, and holds a special place in the hearts of devotees. Surrounded by lush green hills and the majestic Himalayas, Kaichi Dham offers a tranquil environment for spiritual seekers and nature enthusiasts alike.
                </p>
            </div>
            <div className="container-fluid faq-section pb-5">
                <div className="container pb-5">
                    <div className="row mt-5 g-5 align-items-center">
                        <div className="col-xl-6 wow fadeInRight" data-wow-delay="0.4s">
                            <div>
                                <h4 className="text-primary">Tour Details</h4>
                                <div className="row g-3">
                                    <div className="col text-primary">
                                        <div className="accordion accordion-flush bg-light" id="accordionFlushExample1">
                                            <div className="accordion-item">
                                                <h2 className="accordion-header">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne1" aria-expanded="false" aria-controls="flush-collapseOne1">
                                                        Day 1
                                                    </button>
                                                </h2>
                                                <div id="flush-collapseOne1" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample1">
                                                    <div className="accordion-body">
                                                        Pickup location to kaichi dham temple. Evening temple arti.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion accordion-flush mt-2 bg-light" id="accordionFlushExample2">
                                            <div className="accordion-item">
                                                <h2 className="accordion-header">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne2" aria-expanded="false" aria-controls="flush-collapseOne2">
                                                        Day 2
                                                    </button>
                                                </h2>
                                                <div id="flush-collapseOne2" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample2">
                                                    <div className="accordion-body">
                                                        Baba ji char dham Yatra kaichi dham.kakrighat.hanuman gari. Bhomiya dhaar.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Link to='/car-book-page'><button className="btn btn-primary rounded-pill py-3 px-5 mt-4">Book Now</button></Link>
                        </div>
                        <div className="col-xl-6 wow fadeInRight" data-wow-delay="0.4s">
                            <img src="/img/Collage/collageKD.png" className="img-fluid" style={{height:'600px',width:'600px'}} alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default KainchiDhamTour
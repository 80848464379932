import React from 'react'
import { Link } from 'react-router-dom'

const TourPackage = () => {
    return (
        <>
            <div className="container-fluid py-5">
                <div class="container-fluid blog py-5">
                    <div class="container py-5">
                        <div className="text-center mx-auto pb-5 wow fadeInUp" data-wow-delay="0.2s" style={{ maxWidth: '800px' }}>
                            <h4 className="text-primary">Featured Packages</h4>
                            <h1 className="display-4 mb-4">Explore Our Latest Tour Packages</h1>
                            <p className="mb-0 text-dark">
                                Discover a range of exciting and memorable tours designed to offer you the best experiences. Whether you're looking for a relaxing getaway or an adventure-filled vacation, our packages cater to all preferences. Enjoy personalized services, comfortable accommodations, and expert guidance throughout your journey. Explore now to embark on your next adventure!
                            </p>
                        </div>
                        <div class="row g-4 justify-content-center">
                            <div class="col-lg-6 col-xl-4 wow fadeInUp" data-wow-delay="0.2s">
                                <div class="blog-item">
                                    <div class="blog-img">
                                        <img src="/img/Places/nainital-lake.jpg" class="img-fluid rounded-top w-100" alt="" style={{ height: '300px' }} />
                                        <div class="blog-categiry py-2 px-4">
                                            <span>Tour Packages</span>
                                        </div>
                                    </div>
                                    <div class="blog-content p-4 text-center">
                                        <h4 class="h4 d-inline-block mb-3 bg-primary text-white p-4">Nainital tour package 3 night 4 days</h4>
                                        <p className="mb-3 text-dark text-start">
                                            Embark on a mesmerizing journey to Nainital, a stunning hill station nestled in the Kumaon region of Uttarakhand. Known for its scenic beauty, pleasant weather, and serene environment, Nainital offers a perfect escape from the hustle and bustle of city life. Our Nainital Tour Package is designed to give you an unforgettable experience, whether you're looking for relaxation or adventure.
                                        </p>
                                        <Link to='/tour-package/nainital'><button className="btn btn-primary w-100 py-3">Explore now</button></Link>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-xl-4 wow fadeInUp" data-wow-delay="0.4s">
                                <div class="blog-item">
                                    <div class="blog-img">
                                        <img src="/img/Places/jimmy-carbett.jpeg" class="img-fluid rounded-top w-100" alt="" style={{ height: '300px' }} />
                                        <div class="blog-categiry py-2 px-4">
                                            <span>Tour Packages</span>
                                        </div>
                                    </div>
                                    <div class="blog-content p-4 text-center">
                                        <h4 class="h4 d-inline-block mb-3 bg-primary text-white p-4">Jim Corbett park Tour 2 night 3 days</h4>
                                        <p className="mb-3 text-dark text-start">
                                            Embark on an exhilarating adventure to Jim Corbett National Park, one of India's premier wildlife sanctuaries located in Uttarakhand. Known for its rich biodiversity and stunning landscapes, Jim Corbett offers a unique opportunity to witness majestic wildlife, including the elusive Bengal tiger, in their natural habitat. Our Jim Corbett Tour Package is designed for nature lovers.
                                        </p>
                                        <Link to='/tour-package/JimCarbett'> <button className="btn btn-primary w-100 py-3">Explore Now</button></Link>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-xl-4 wow fadeInUp" data-wow-delay="0.6s">
                                <div class="blog-item">
                                    <div class="blog-img">
                                        <img src="img/Places/haridwar.jpg" class="img-fluid rounded-top w-100" alt="" style={{ height: '300px' }} />
                                        <div class="blog-categiry py-2 px-4">
                                            <span>Tour Packages</span>
                                        </div>
                                    </div>
                                    <div class="blog-content p-4 text-center">
                                        <h4 class="h4 d-inline-block bg-primary text-white p-4">Haridwar Rishikesh tour package 4 night 5 dayss</h4>
                                        <p className="mb-3 text-dark text-start">
                                            Discover the spiritual essence and natural beauty of Haridwar and Rishikesh, two of Uttarakhand’s most revered and picturesque destinations. From the sacred ghats of Haridwar to the tranquil banks of the Ganges in Rishikesh, this tour offers a perfect blend of spirituality, and relaxation. Whether you're looking to participate in ancient rituals, enjoy yoga and meditation .
                                        </p>
                                        <Link to='/tour-package/haridwar'> <button className="btn btn-primary w-100 py-3">Explore Now</button></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="container py-5">
                        <div class="row g-4 justify-content-center">
                            <div class="col-lg-6 col-xl-4 wow fadeInUp" data-wow-delay="0.2s">
                                <div class="blog-item">
                                    <div class="blog-img">
                                        <img src="img/Places/Kedarnath.jpg" class="img-fluid rounded-top w-100" alt="" style={{ height: '300px' }} />
                                        <div class="blog-categiry py-2 px-4">
                                            <span>Tour Packages</span>
                                        </div>
                                    </div>
                                    <div class="blog-content p-4 text-center">
                                        <h4 class="h4 d-inline-block mb-3 bg-primary text-white p-4">Char dham Yatra package 9 night 10 days</h4>
                                        <p className="mb-3 text-dark text-start">
                                            Experience a transformative journey through the Char Dham, the four sacred shrines nestled in the majestic Himalayas of Uttarakhand. This pilgrimage covers Yamunotri, Gangotri, Kedarnath, and Badrinath, each representing a significant aspect of Hindu spirituality. Our Char Dham Tour offers a spiritual adventure that includes breathtaking landscapes, sacred rituals, and a deep connection with the divine.
                                        </p>
                                        <Link to='/tour-package/chardham'> <button className="btn btn-primary w-100 py-3">Explore now</button></Link>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-xl-4 wow fadeInUp" data-wow-delay="0.4s">
                                <div class="blog-item">
                                    <div class="blog-img">
                                        <img src="img/Places/kasauni2.jpg" class="img-fluid rounded-top w-100" style={{ height: '300px' }} alt="" />
                                        <div class="blog-categiry py-2 px-4">
                                            <span>Tour Packages</span>
                                        </div>
                                    </div>
                                    <div class="blog-content p-4 text-center">
                                        <h4 class="h4 d-inline-block mb-3 bg-primary text-white p-4">Kausani tour package 3 night 4 days</h4>
                                        <p className="mb-4 text-dark text-start">
                                            Escape to the serene hill station of Kasauni, nestled in the heart of Uttarakhand. Known for its panoramic views of the Himalayan peaks and lush green valleys, Kasauni offers a peaceful retreat from the hustle and bustle of city life. Our Kasauni Tour Package is designed to provide you with an immersive experience in nature, including scenic vistas, local culture, and relaxing moments in one of the most beautiful places in the region.
                                        </p>
                                        <Link to='/tour-package/kausani'> <button className="btn btn-primary w-100 py-3">Explore Now</button></Link>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-xl-4 wow fadeInUp" data-wow-delay="0.6s">
                                <div class="blog-item">
                                    <div class="blog-img">
                                        <img src="/img/Places/Jageswar-dham.jpg" class="img-fluid rounded-top w-100" alt="" style={{ height: '300px' }} />
                                        <div class="blog-categiry py-2 px-4">
                                            <span>Tour Packages</span>
                                        </div>
                                    </div>
                                    <div class="blog-content p-4 text-center">
                                        <h4 class="h4 d-inline-block mb-3 bg-primary text-white p-4">Jageshwar Dham yatra 2 night 3 days</h4>
                                        <p className="mb-4 text-dark text-start">
                                            Immerse yourself in the tranquility of Jageshwar Dham with our 2 nights and 3 days Yatra package. Nestled in the scenic Kumaon region of Uttarakhand, Jageshwar Dham is renowned for its ancient temples dedicated to Lord Shiva. This package offers a perfect blend of spirituality, history, and natural beauty, providing a rejuvenating experience in one of the most revered pilgrimage sites in the Himalayas.
                                        </p>
                                        <Link to='/tour-package/jageswar-dham'> <button className="btn btn-primary w-100 py-3">Explore Now</button></Link>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-xl-4 wow fadeInUp" data-wow-delay="0.6s">
                                <div class="blog-item">
                                    <div class="blog-img">
                                        <img src="/img/Places/almora1.jpg" class="img-fluid rounded-top w-100" alt="" style={{ height: '300px' }} />
                                        <div class="blog-categiry py-2 px-4">
                                            <span>Tour Packages</span>
                                        </div>
                                    </div>
                                    <div class="blog-content p-4 text-center">
                                        <h6 class="h3 d-inline-block mb-3 text-white bg-primary p-4">Almora Tour Packages 2 night 3 days</h6>
                                        <p className="mb-4 text-dark text-start">
                                            Almora is a charming hill station nestled in the Kumaon region of Uttarakhand. Known for its rich cultural heritage, stunning natural beauty, and pleasant weather, Almora is a popular destination for nature lovers and those seeking a tranquil retreat.
                                        </p>
                                        <Link to='/tour-package/almora'> <button className="btn btn-primary w-100 py-3">Explore Now</button></Link>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-xl-4 wow fadeInUp" data-wow-delay="0.6s">
                                <div class="blog-item">
                                    <div class="blog-img">
                                        <img src="/img/Places/Ranikhet1.jpg" class="img-fluid rounded-top w-100" alt="" style={{ height: '300px' }} />
                                        <div class="blog-categiry py-2 px-4">
                                            <span>Tour Packages</span>
                                        </div>
                                    </div>
                                    <div class="blog-content p-4 text-center">
                                        <h6 class="h3 d-inline-block mb-3 text-white bg-primary p-4">Ranikhet Munsiyari Tour Packages 6 night 7 days</h6>
                                        <p className="mb-4 text-dark text-start">
                                            Ranikhet and Munsiyari are picturesque hill stations in the Indian state of Uttarakhand. Known for their serene landscapes, panoramic views of the Himalayas, and rich cultural heritage, they offer a perfect retreat for nature lovers and adventure enthusiasts.
                                        </p>
                                        <Link to='/tour-package/Ranikhet-Munsiyari'> <button className="btn btn-primary w-100 py-3">Explore Now</button></Link>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-xl-4 wow fadeInUp" data-wow-delay="0.6s">
                                <div class="blog-item">
                                    <div class="blog-img">
                                        <img src="/img/Places/kaichi-dham2.jpg" class="img-fluid rounded-top w-100" alt="" style={{ height: '300px' }} />
                                        <div class="blog-categiry py-2 px-4">
                                            <span>Tour Packages</span>
                                        </div>
                                    </div>
                                    <div class="blog-content p-4 text-center">
                                        <h6 class="h3 d-inline-block mb-3 text-white bg-primary p-4">Kaichi dham tour package 1 night 2 days
                                        </h6>
                                        <p className="mb-4 text-dark text-start">
                                        Kaichi Dham is a revered pilgrimage site nestled in the serene landscapes of Uttarakhand, India. The temple complex is dedicated to the revered saint, Kedarnath Ji, and holds a special place in the hearts of devotees. Surrounded by lush green hills and the majestic Himalayas.
                                        </p>
                                        <Link to='/tour-package/kaichi-dham'> <button className="btn btn-primary w-100 py-3">Explore Now</button></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default TourPackage
import React from 'react';

const Blog = () => {
    return (
        <>
            <div className="container-fluid about py-5">
                <div className="text-center mx-auto pb-5 wow bg-white fadeInUp" data-wow-delay="0.2s" style={{ maxWidth: '800px' }}>
                    <h4 className="text-primary">Our Guides</h4>
                    <h1 className="display-4 mb-4 text-primary">Discover Our Guides</h1>
                    <p className="mb-0 text-dark">Experts Who Make Every Journey Memorable
                    </p>
                </div>
                <div className="container py-5">
                    <div className="row g-5">
                        <div className="col-xl-6 wow fadeInLeft" data-wow-delay="0.2s">
                            <div className="about-item-content rounded p-5 h-100">
                                <h4 className="text-primary">About Our Guides</h4>
                                <h1 className="display-4 mb-4">Meet Our Expert Guides</h1>
                                <p className='text-dark'>At Heart Hill Tours & Travels, our expert guides are the cornerstone of our travel experiences. Each guide brings extensive knowledge and passion for their region, ensuring that every journey is both informative and enjoyable.</p>
                                <p className='text-dark'>Our guides are dedicated to providing personalized service, making sure that you not only see the sights but also understand the culture, history, and local secrets of your destination.</p>
                                <p className='text-dark'><i className="fa fa-check text-primary me-3"></i>Local Knowledge and Insights</p>
                                <p className='text-dark'><i className="fa fa-check text-primary me-3"></i>Personalized Attention</p>
                                <p className="text-dark mb-4"><i className="fa fa-check text-primary me-3"></i>Passionate and Experienced</p>
                            </div>
                        </div>
                        <div className="col-xl-6 wow fadeInRight" data-wow-delay="0.2s">
                            <div className="bg-white rounded p-5 h-100">
                                <div className="row g-4 justify-content-center">
                                    <div className="col-12">
                                        <div className="rounded bg-light">
                                            <img src="/img/Image-Gallery/gallery-32.jpg" className="img-fluid rounded w-100" alt="Travel Guide" />
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="counter-item bg-light rounded p-3 h-100">
                                            <div className="counter-counting">
                                                <span className="h1 fw-bold text-primary">2000 +</span>
                                            </div>
                                            <h4 className="mb-0 text-dark">Tours</h4>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="counter-item bg-light rounded p-3 h-100">
                                            <div className="counter-counting">
                                                <span className="h1 fw-bold text-primary">1000 +</span>
                                            </div>
                                            <h4 className="mb-0 text-dark">Satisfied Travelers</h4>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="counter-item bg-light rounded p-3 h-100">
                                            <div className="counter-counting">
                                                <span className="h1 fw-bold text-primary">100 +</span>
                                            </div>
                                            <h4 className="mb-0 text-dark">Yearly Tours</h4>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="counter-item bg-light rounded p-3 h-100">
                                            <div className="counter-counting">
                                                <span className="h1 fw-bold text-primary">200 +</span>
                                            </div>
                                            <h4 className="mb-0 text-dark">Guided Tours Conducted</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid feature pb-5">
                <div className="container pb-5">
                    <div className="text-center mx-auto pb-5 wow fadeInUp" data-wow-delay="0.2s" style={{ maxWidth: '800px' }}>
                        <h4 className="text-primary">Our Unique Features</h4>
                        <h1 className="display-4 mb-4">Why Our Guides Stand Out</h1>
                        <p className="mb-0 text-dark">Discover the exceptional qualities of our guides that make your travel experience unique and memorable.</p>
                    </div>
                    <div className="row g-4">
                        <div className="col-md-6 col-lg-6 col-xl-3 wow fadeInUp" data-wow-delay="0.2s">
                            <div className="feature-item p-4 pt-0">
                                <div className="feature-icon p-4 mb-4">
                                    <i className="fa fa-map-marked-alt fa-3x"></i>
                                </div>
                                <h4 className="mb-4">In-Depth Local Knowledge</h4>
                                <p className="text-dark mb-4">Our guides are local experts who provide deep insights into your destination's history, culture, and hidden gems.</p>

                            </div>
                        </div>
                        <div className="col-md-6 col-lg-6 col-xl-3 wow fadeInUp" data-wow-delay="0.4s">
                            <div className="feature-item p-4 pt-0">
                                <div className="feature-icon p-4 mb-4">
                                    <i className="fa fa-user-tie fa-3x"></i>
                                </div>
                                <h4 className="mb-4">Professional Guides</h4>
                                <p className="mb-4 text-dark">Our guides are trained professionals dedicated to providing exceptional service and enhancing your travel experience.</p>

                            </div>
                        </div>
                        <div className="col-md-6 col-lg-6 col-xl-3 wow fadeInUp" data-wow-delay="0.6s">
                            <div className="feature-item p-4 pt-0">
                                <div className="feature-icon p-4 mb-4">
                                    <i className="fa fa-comments fa-3x"></i>
                                </div>
                                <h4 className="mb-4">Engaging Stories</h4>
                                <p className="mb-4 text-dark">Experience engaging stories and anecdotes that bring your travel destinations to life, making your journey unforgettable.</p>

                            </div>
                        </div>
                        <div className="col-md-6 col-lg-6 col-xl-3 wow fadeInUp" data-wow-delay="0.8s">
                            <div className="feature-item p-4 pt-0">
                                <div className="feature-icon p-4 mb-4">
                                    <i className="fa fa-thumbs-up fa-3x"></i>
                                </div>
                                <h4 className="mb-4">Exceptional Service</h4>
                                <p className="mb-4 text-dark">Our guides are committed to providing top-notch service, ensuring that every aspect of your tour meets the highest standards.</p>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid faq-section py-5">
                <div className="container py-5">
                    <div className="text-center mx-auto pb-5 wow fadeInUp" data-wow-delay="0.2s" style={{ maxWidth: '800px' }}>
                        <h4 className="text-primary">Frequently Asked Questions</h4>
                        <h1 className="display-4 mb-4">Travel Guides FAQ</h1>
                        <p className="text-dark mb-0">Find answers to common questions about our travel guides and how they can enhance your experience.</p>
                    </div>
                    <div className="row g-5">
                        <div className="col-lg-6 wow fadeInLeft" data-wow-delay="0.2s">
                            <div className="accordion pt-5" id="accordionExample">
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingOne">
                                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                            <b>Q: What qualifications do your guides have?</b>
                                        </button>
                                    </h2>
                                    <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                        <div className="accordion-body rounded text-dark">
                                            A: Our guides are highly qualified with certifications in their respective areas of expertise. They undergo continuous training to provide the best experience.
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingTwo">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                            <b>Q: Can guides accommodate special requests or needs?</b>
                                        </button>
                                    </h2>
                                    <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                        <div className="accordion-body text-dark">
                                            A: Yes, our guides are flexible and happy to accommodate special requests or needs to ensure your travel experience is personalized and enjoyable.
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingThree">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                            <b>Q: How do guides enhance the travel experience?</b>
                                        </button>
                                    </h2>
                                    <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                        <div className="accordion-body text-dark">
                                            A: Guides provide local insights, share engaging stories, and help navigate unfamiliar places. Their expertise ensures you get the most out of your visit.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 wow fadeInRight" data-wow-delay="0.4s">
                            <img src="/img/Collage/collage-3.jpg" className="img-fluid w-100" style={{ height: '500px' }} alt="Guide Interaction" />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Blog;

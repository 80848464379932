import React from 'react'
import { Link } from 'react-router-dom'

const Header = () => {
    return (
        <>
            <div className="container-fluid topbar px-0 px-lg-4 bg-primary py-2 d-none d-lg-block">
                <div className="row gx-0 bg-primary align-items-center">
                    <div className="col-lg-8 text-center text-lg-start mb-lg-0">
                        <div className="d-flex flex-wrap ">
                            <div className="pe-3">
                                <a href="mailto:vinodsbisht2492@gmail.com" className="small text-white"><i className="fas fa-envelope  text-white me-2"></i> <b>vinodsbisht2492@gmail.com</b></a>
                            </div>
                            <div className="ps-3">
                                <a href="tel:7617712274" className="text-white small"><i className="fa fa-phone text-white me-2"></i> <b>7617712274</b></a>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 text-center text-lg-end">
                        <div className="d-flex justify-content-end">
                            <div className="d-flex pe-3">
                                <a className="btn p-0 text-white me-3" href="https://www.facebook.com/vinod.bisht.56211?mibextid=ZbWKwL"><i className="fab fa-facebook-f"></i></a>
                                <a className="btn p-0 text-white me-3" href="#"><i className="fab fa-instagram"></i></a>
                                <a className="btn p-0 text-white me-0" href="https://www.youtube.com/@hillstaxivloguk01"><i className="fab fa-youtube"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid px-0 px-lg-4 py-lg-0 mt-2 mb-2 sticky-top bg-light">
                <nav className="navbar navbar-expand-lg">
                    <Link to="/" className="navbar-brand p-0 me-5">
                        <h4 className="text-primary mb-0">
                            <img src="/img/HillHeartLogo-.png" className='pt-0' style={{ height: '65px' }} alt="Logo" /> 
                        </h4>
                    </Link>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <i className="bi bi-list"></i>
                    </button>

                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav mr-auto">
                            <li className="nav-item active">
                                <Link to="/" className="nav-link btn btn-primary rounded-pill py-2 px-4 ms-3 flex-shrink-0">Home</Link>
                            </li>
                            <li className="nav-item">
                                <Link to="/about" className="nav-link btn btn-primary rounded-pill py-2 px-4 ms-3 flex-shrink-0">About</Link>
                            </li>
                            <li className="nav-item">
                                <Link to="/taxi-service" className="nav-link btn btn-primary rounded-pill py-2 px-4 ms-3 flex-shrink-0">Taxi Service</Link>
                            </li>


                            <li className="nav-item">
                                <Link to="/tour-package" className="nav-link btn btn-primary rounded-pill py-2 px-4 ms-3 flex-shrink-0">Tour Package</Link>
                            </li>
                            <li className="nav-item">
                                <Link to="/blog" className="nav-link btn btn-primary rounded-pill py-2 px-4 ms-3 flex-shrink-0">Guide</Link>
                            </li>
                            <li className="nav-item">
                                <Link to="/contact" className="nav-link btn btn-primary rounded-pill py-2 px-4 ms-3 flex-shrink-0">Contact</Link>
                            </li>
                            <li className="nav-item">
                                <Link to="/car-book-page" className="btn btn-primary rounded-pill py-2 px-4 ms-3 flex-shrink-0">Book Now</Link>
                            </li>
                        </ul>
                    </div>
                </nav>
            </div>

        </>
    )
}

export default Header
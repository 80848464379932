import React, { useState } from 'react';

const ImageGallery = () => {
  const [currentImage, setCurrentImage] = useState(null);

  const images = [
    'img/Image-Gallery/gallery-1.jpg',
    'img/Image-Gallery/gallery-2.jpg',
    'img/Image-Gallery/gallery-3.jpg',
    'img/Image-Gallery/gallery-4.jpg',
    'img/Image-Gallery/gallery-5.jpg',
    'img/Image-Gallery/gallery-6.jpg',
    'img/Image-Gallery/gallery-7.jpg',
    'img/Image-Gallery/gallery-8.jpg',
    'img/Image-Gallery/gallery-9.jpg',
    'img/Image-Gallery/gallery-10.jpg',
    'img/Image-Gallery/gallery-11.jpg',
    'img/Image-Gallery/gallery-12.jpg',
    'img/Image-Gallery/gallery-13.jpg',
    'img/Image-Gallery/gallery-14.jpg',
    'img/Image-Gallery/gallery-15.jpg',
    'img/Image-Gallery/gallery-16.jpg',
    'img/Image-Gallery/gallery-17.jpg',
    'img/Image-Gallery/gallery-18.jpg',
    'img/Image-Gallery/gallery-19.jpg',
    'img/Image-Gallery/gallery-20.jpg',
    'img/Image-Gallery/gallery-21.jpg',
    'img/Image-Gallery/gallery-22.jpg',
    'img/Image-Gallery/gallery-23.jpg',
    'img/Image-Gallery/gallery-24.jpg',
    'img/Image-Gallery/gallery-25.jpg',
    'img/Image-Gallery/gallery-26.jpg',
    'img/Image-Gallery/gallery-27.jpg',
    'img/Image-Gallery/gallery-28.jpg',
    'img/Image-Gallery/gallery-29.jpg',
    'img/Image-Gallery/gallery-30.jpg',
    'img/Image-Gallery/gallery-31.jpg',
    'img/Image-Gallery/gallery-32.jpg',
    'img/Image-Gallery/gallery-33.jpg',
    'img/Image-Gallery/gallery-34.jpg',
    'img/Image-Gallery/gallery-35.jpg',
    'img/Image-Gallery/gallery-36.jpg',
    'img/Image-Gallery/gallery-37.jpg',
    'img/Image-Gallery/gallery-38.jpg',
    'img/Image-Gallery/gallery-39.jpg',
    'img/Image-Gallery/gallery-40.jpg',
    'img/Image-Gallery/gallery-41.jpg',
    'img/Image-Gallery/gallery-42.jpg',
    'img/Image-Gallery/gallery-43.jpg',
    'img/Image-Gallery/gallery-44.jpg',
    'img/Image-Gallery/gallery-45.jpg',
    'img/Image-Gallery/gallery-46.jpg',
    'img/Image-Gallery/gallery-47.jpg',
    'img/Image-Gallery/gallery-48.jpg',
    'img/Image-Gallery/gallery-49.jpg',
    'img/Image-Gallery/gallery-49.jpg',
    'img/Image-Gallery/gallery-50.jpg',
    'img/Image-Gallery/gallery-51.jpg',
    'img/Image-Gallery/gallery-53.jpg',
    'img/Image-Gallery/gallery-54.jpg',
    'img/Image-Gallery/gallery-55.jpg',
    'img/Image-Gallery/gallery-56.jpg',
    'img/Image-Gallery/gallery-57.jpg',
    'img/Image-Gallery/gallery-58.jpg',
    'img/Image-Gallery/gallery-59.jpg',
    'img/Image-Gallery/gallery-60.jpg',
    'img/Image-Gallery/gallery-61.jpg',
    'img/Image-Gallery/gallery-63.jpg',
    'img/Image-Gallery/gallery-63.jpg',
    'img/Image-Gallery/gallery-64.jpg',
    'img/Image-Gallery/gallery-65.jpg',
    'img/Image-Gallery/gallery-66.jpg',
    'img/Image-Gallery/gallery-67.jpg',
    'img/Image-Gallery/gallery-68.jpg',
    'img/Image-Gallery/gallery-69.jpg',
    'img/Image-Gallery/gallery-70.jpg',
    'img/Image-Gallery/gallery-71.jpg',
    'img/Image-Gallery/gallery-72.jpg',
    'img/Image-Gallery/gallery-73.jpg',
    'img/Image-Gallery/gallery-74.jpg',
    'img/Image-Gallery/gallery-75.jpg',
    'img/Image-Gallery/gallery-76.jpg',
    'img/Image-Gallery/gallery-77.jpg',
    'img/Image-Gallery/gallery-78.jpg',
    'img/Image-Gallery/gallery-79.jpg',
    'img/Image-Gallery/gallery-80.jpg',
    'img/Image-Gallery/gallery-81.jpg',
    'img/Image-Gallery/gallery-82.jpg',
    'img/Image-Gallery/gallery-83.jpg',
    'img/Image-Gallery/gallery-84.jpg',

  ];
  const imagesBirds = [
    '/img/Birds/Bird1.jpg',
    '/img/Birds/bird2.jpg',
    '/img/Birds/Bird3.jpg',
    '/img/Birds/Bird4.jpg',
    '/img/Birds/Bird5.jpg',
    '/img/Birds/Bird6.jpg',
    '/img/Birds/Bird7.jpg',
    '/img/Birds/Bird8.jpg',
    '/img/Birds/Bird9.jpg',
    '/img/Birds/Bird10.jpg',
    '/img/Birds/Bird11.jpg',
    '/img/Birds/Bird12.jpg'
  ];
  const handleImageClick = (src) => {
    setCurrentImage(src);
  };

  return (
    <>
      <div>
        <div className="text-center mx-auto pb-5 wow fadeInUp" data-wow-delay="0.2s" style={{ maxWidth: '800px' }}>
          <h4 className="text-primary">Tour Images</h4>
          <h1 className="display-4 mb-4">Image Gallery</h1>
          <p className="mb-0">Explore a selection of beautiful images. Click on any image to view it in a larger size.</p>
        </div>
        <style>
          {`
                    .gallery-container {
                        display: flex;
                        flex-wrap: wrap;
                        gap: 10px;
                        justify-content: center;
                    }

                    .gallery-image {
                        width: 100%;
                        max-width: 200px;
                        height: auto;
                        cursor: pointer;
                        border: 2px solid #ddd;
                        border-radius: 8px;
                        transition: transform 0.2s;
                    }

                    .gallery-image:hover {
                        transform: scale(1.05);
                    }

                    .modal {
                        position: fixed;
                        top:80px;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        background: rgba(0, 0, 0, 0.7);
                        display: ${currentImage ? 'flex' : 'none'};
                        justify-content: center;
                        align-items: center;
                        z-index: 1000;
                    }

                    .modal-content {
                        position: relative;
                        width: 500px;
                        height: 500px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }

                    .modal-content img {
                        max-width: 100%;
                        max-height: 100%;
                        border-radius: 8px;
                    }

                    .modal-close {
                        position: absolute;
                        top: 10px;
                        right: 10px;
                        font-size: 24px;
                        color: white;
                        cursor: pointer;
                    }
                `}

        </style>

        <div className="gallery-container">
          {images.map((src, index) => (
            <img
              key={index}
              src={src}
              alt={`Gallery ${index}`}
              className="gallery-image text-black"
              onClick={() => handleImageClick(src)}
            />
          ))}
        </div>

        {currentImage && (
          <div className="modal" onClick={() => setCurrentImage(null)}>
            <div className="modal-content">
              <b><span className="modal-close text-white" onClick={() => setCurrentImage(null)}>&times;</span></b>
              <img src={currentImage} alt="Enlarged view" />
            </div>
          </div>
        )}
      </div>
      <div>
        <div className="text-center mx-auto pb-5 wow fadeInUp mt-5" data-wow-delay="0.2s" style={{ maxWidth: '800px' }}>
          <h4 className="text-primary">Bird Watching</h4>
          <h1 className="display-4 mb-4">Bird Watching</h1>
          <p className="mb-0 text-dark">
            Birdwatching, or birding, is the observing of birds, either as a recreational activity or as a form of citizen science. A birdwatcher may observe by using their naked eye, by using a visual enhancement device such as binoculars or a telescope, by listening for bird sounds, watching public webcams, or by viewing smart bird feeder cameras. Most birdwatchers pursue this activity for recreational or social reasons, unlike ornithologists, who engage in the study of birds using formal scientific method.
          </p>
        </div>
        <style>
          {`
                    .gallery-container {
                        display: flex;
                        flex-wrap: wrap;
                        gap: 10px;
                        justify-content: center;
                    }

                    .gallery-image {
                        width: 100%;
                        max-width: 200px;
                        height: auto;
                        cursor: pointer;
                        border: 2px solid #ddd;
                        border-radius: 8px;
                        transition: transform 0.2s;
                    }

                    .gallery-image:hover {
                        transform: scale(1.05);
                    }

                    .modal {
                        position: fixed;
                        top:80px;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        background: rgba(0, 0, 0, 0.7);
                        display: ${currentImage ? 'flex' : 'none'};
                        justify-content: center;
                        align-items: center;
                        z-index: 1000;
                    }

                    .modal-content {
                        position: relative;
                        width: 500px;
                        height: 500px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }

                    .modal-content img {
                        max-width: 100%;
                        max-height: 100%;
                        border-radius: 8px;
                    }

                    .modal-close {
                        position: absolute;
                        top: 10px;
                        right: 10px;
                        font-size: 24px;
                        color: white;
                        cursor: pointer;
                        text:bold
                    }
                `}

        </style>

        <div className="gallery-container">
          {imagesBirds.map((src, index) => (
            <img
              key={index}
              src={src}
              alt={`Gallery ${index}`}
              className="gallery-image"
              onClick={() => handleImageClick(src)}
            />
          ))}
        </div>

        {currentImage && (
          <div className="modal" onClick={() => setCurrentImage(null)}>
            <div className="modal-content">
              <span className="modal-close text-dark" onClick={() => setCurrentImage(null)}>&times;</span>
              <img src={currentImage} style={{width:'100%',height:'100%'}} alt="Enlarged view" />
            </div>
          </div>
        )}
      </div>
    </>

  );
};

export default ImageGallery;
